import { Connection } from "./Connection";
var Client = /** @class */ (function () {
    function Client(connection) {
        var _this = this;
        this._connection = connection;
        var process_ws_message = function (message) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
            switch (message.action) {
                case "connection-joined":
                    (_a = _this.on_connection_joined) === null || _a === void 0 ? void 0 : _a.call(_this, message);
                    break;
                case "connection-opened":
                    (_b = _this.on_connection_opened) === null || _b === void 0 ? void 0 : _b.call(_this, message);
                    break;
                case "connection-left":
                    (_c = _this.on_connection_left) === null || _c === void 0 ? void 0 : _c.call(_this, message);
                    break;
                case "message-ws":
                case "server-broadcast-ws":
                case "room-broadcast-ws":
                    (_d = _this.on_ws_message) === null || _d === void 0 ? void 0 : _d.call(_this, message);
                    break;
                case "message-dc":
                case "server-broadcast-dc":
                case "room-broadcast-dc":
                    (_e = _this.on_dc_message) === null || _e === void 0 ? void 0 : _e.call(_this, message);
                    break;
                case "list-connections":
                    (_f = _this.on_list_connections) === null || _f === void 0 ? void 0 : _f.call(_this, message);
                    break;
                case "room-created":
                    (_g = _this.on_room_created) === null || _g === void 0 ? void 0 : _g.call(_this, message);
                    break;
                case "room-closed":
                    var room_closed_message = message;
                    if (_this._room_id === room_closed_message.payload.room_id) {
                        _this._room_id = undefined;
                    }
                    (_h = _this.on_room_closed) === null || _h === void 0 ? void 0 : _h.call(_this, message);
                    break;
                case "joined-room":
                    var joined_room_message = message;
                    if (_this.id === joined_room_message.payload.connection_id) {
                        _this._room_id = joined_room_message.payload.room_id;
                    }
                    (_j = _this.on_joined_room) === null || _j === void 0 ? void 0 : _j.call(_this, message);
                    break;
                case "left-room":
                    (_k = _this.on_left_room) === null || _k === void 0 ? void 0 : _k.call(_this, message);
                    break;
                case "list-rooms":
                    (_l = _this.on_list_rooms) === null || _l === void 0 ? void 0 : _l.call(_this, message);
                    break;
                case "list-room-connections":
                    (_m = _this.on_list_room_connections) === null || _m === void 0 ? void 0 : _m.call(_this, message);
                    break;
                case "pull-connection-client-data":
                    (_o = _this.on_pull_connection_data) === null || _o === void 0 ? void 0 : _o.call(_this, message);
                    break;
                case "pull-room-client-data":
                    (_p = _this.on_pull_room_data) === null || _p === void 0 ? void 0 : _p.call(_this, message);
                    break;
                case "error":
                    (_q = _this.on_error) === null || _q === void 0 ? void 0 : _q.call(_this, message);
                    break;
            }
        };
        this._connection.on_ws_message(function (data) {
            var message = JSON.parse(data);
            if (message.action === "message-batch") {
                for (var _i = 0, _a = message.payload.messages; _i < _a.length; _i++) {
                    var msg = _a[_i];
                    var sub_message = JSON.parse(msg);
                    process_ws_message(sub_message);
                }
            }
            else {
                process_ws_message(message);
            }
        });
        var process_dc_message = function (message) {
            var _a, _b;
            switch (message.action) {
                case "message-ws":
                case "server-broadcast-ws":
                case "room-broadcast-ws":
                    (_a = _this.on_ws_message) === null || _a === void 0 ? void 0 : _a.call(_this, message);
                    break;
                case "message-dc":
                case "server-broadcast-dc":
                case "room-broadcast-dc":
                    (_b = _this.on_dc_message) === null || _b === void 0 ? void 0 : _b.call(_this, message);
                    break;
            }
        };
        this._connection.on_dc_message(function (data) {
            var message = JSON.parse(data);
            if (message.action === "message-batch") {
                for (var _i = 0, _a = message.payload.messages; _i < _a.length; _i++) {
                    var msg = _a[_i];
                    var sub_message = JSON.parse(msg);
                    process_dc_message(sub_message);
                }
            }
            else {
                process_dc_message(message);
            }
        });
    }
    Object.defineProperty(Client.prototype, "id", {
        get: function () {
            return this._connection.id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "room_id", {
        get: function () {
            return this._room_id;
        },
        enumerable: false,
        configurable: true
    });
    Client.create = function (conf) {
        return Connection.create(conf).then(function (connection) {
            return new Client(connection);
        });
    };
    Client.prototype.list_connections = function () {
        this._connection.send_ws(JSON.stringify({
            action: "list-connections",
            payload: {},
        }));
    };
    Client.prototype.close = function () {
        this._connection.close();
    };
    Client.prototype.message_ws = function (connections, message) {
        if (connections.length === 0) {
            return;
        }
        this._connection.send_ws(JSON.stringify({
            action: "message-ws",
            payload: {
                recipients: connections,
                message: message,
            }
        }));
    };
    Client.prototype.message_dc = function (connections, message) {
        if (connections.length === 0) {
            return;
        }
        this._connection.send_dc(JSON.stringify({
            action: "message-dc",
            payload: {
                recipients: connections,
                message: message,
            }
        }));
    };
    Client.prototype.server_broadcast_ws = function (message) {
        this._connection.send_ws(JSON.stringify({
            action: "server-broadcast-ws",
            payload: {
                message: message,
            }
        }));
    };
    Client.prototype.server_broadcast_dc = function (message) {
        this._connection.send_dc(JSON.stringify({
            action: "server-broadcast-dc",
            payload: {
                message: message,
            }
        }));
    };
    Client.prototype.create_room = function () {
        this._connection.send_ws(JSON.stringify({
            action: "create-room",
            payload: {}
        }));
    };
    Client.prototype.close_room = function () {
        if (!this._room_id) {
            throw "Can't close undefined room";
        }
        this._connection.send_ws(JSON.stringify({
            action: "close-room",
            payload: {
                room_id: this._room_id,
            }
        }));
    };
    Client.prototype.join_room = function (room_id) {
        // TODO: make server handle this
        // if (this._room_id) {
        //     this.leave_room();
        // }
        this._connection.send_ws(JSON.stringify({
            action: "join-room",
            payload: {
                room_id: room_id,
            }
        }));
    };
    Client.prototype.leave_room = function () {
        this._connection.send_ws(JSON.stringify({
            action: "leave-room",
            payload: {
                room_id: this._room_id,
            }
        }));
        this._room_id = undefined;
    };
    Client.prototype.room_broadcast_ws = function (message) {
        if (!this._room_id) {
            return false;
        }
        this._connection.send_ws(JSON.stringify({
            action: "room-broadcast-ws",
            payload: {
                room_id: this._room_id,
                message: message,
            }
        }));
        return true;
    };
    Client.prototype.room_broadcast_dc = function (message) {
        if (!this._room_id) {
            return false;
        }
        this._connection.send_dc(JSON.stringify({
            action: "room-broadcast-dc",
            payload: {
                room_id: this._room_id,
                message: message,
            }
        }));
    };
    Client.prototype.list_rooms = function () {
        this._connection.send_ws(JSON.stringify({
            action: "list-rooms",
            payload: {},
        }));
    };
    Client.prototype.list_room_connections = function (room_id) {
        this._connection.send_ws(JSON.stringify({
            action: "list-room-connections",
            payload: { room_id: room_id, },
        }));
    };
    Client.prototype.push_connection_data = function (data) {
        this._connection.send_ws(JSON.stringify({
            action: "push-connection-client-data",
            payload: {
                data: data,
            }
        }));
    };
    Client.prototype.pull_connection_data = function () {
        this._connection.send_ws(JSON.stringify({
            action: "pull-connection-client-data",
            payload: {}
        }));
    };
    Client.prototype.push_room_data = function (room_id, data) {
        this._connection.send_ws(JSON.stringify({
            action: "push-room-client-data",
            payload: {
                room_id: room_id,
                data: data,
            }
        }));
    };
    Client.prototype.pull_room_data = function (room_id) {
        this._connection.send_ws(JSON.stringify({
            action: "pull-room-client-data",
            payload: {
                room_id: room_id,
            }
        }));
    };
    return Client;
}());
export { Client };
