var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var Pc_config = /** @class */ (function () {
    function Pc_config() {
        this.iceServers = [{ urls: ['stun:stun.l.google.com:19302'] }];
    }
    return Pc_config;
}());
export { Pc_config };
var Dc_config = /** @class */ (function () {
    function Dc_config(label) {
        this.ordered = false;
        this.maxPacketLifeTime = 100;
        this.label = label;
    }
    return Dc_config;
}());
export { Dc_config };
var Connection_config = /** @class */ (function () {
    function Connection_config(ws, dc, pc, verbose_logs) {
        if (pc === void 0) { pc = new Pc_config(); }
        if (verbose_logs === void 0) { verbose_logs = false; }
        this.pc = new Pc_config();
        this.verbose_logs = false;
        this.ws = ws;
        this.pc = pc;
        this.dc = dc;
        this.verbose_logs = verbose_logs;
    }
    return Connection_config;
}());
export { Connection_config };
var Connection = /** @class */ (function () {
    function Connection() {
        this._verbose_logs = false;
    }
    Connection.create = function (conf) {
        var connection = new Connection();
        connection._verbose_logs = conf.verbose_logs;
        connection._ws = new WebSocket(conf.ws.url, conf.ws.protocols);
        var creationAnswerReceived = false;
        var awaitingCandidates = [];
        connection._ws.onmessage = function (event) {
            connection.log_debug({ e: event.data });
            try {
                var message_1 = JSON.parse(event.data);
                if (message_1.action === "peerconnection-creation-new-candidate") {
                    if (!creationAnswerReceived) {
                        awaitingCandidates.push(message_1);
                    }
                    else {
                        var res = connection._pc.addIceCandidate({
                            candidate: message_1.payload.candidate,
                            sdpMid: message_1.payload.mid,
                        });
                        res
                            .then(function (e) {
                            connection.log_debug({ success: e, pl: message_1 });
                            return e;
                        })
                            .catch(function (e) {
                            console.error({ error: e, pl: message_1 });
                        });
                    }
                    return;
                }
                else if (message_1.action === "peerconnection-creation-answer") {
                    connection.log_debug("remote desc");
                    connection._id = message_1.payload.connection_id;
                    connection._pc.setRemoteDescription({
                        sdp: message_1.payload.sdp,
                        type: message_1.payload.type,
                    });
                    creationAnswerReceived = true;
                    if (awaitingCandidates.length) {
                        for (var _i = 0, awaitingCandidates_1 = awaitingCandidates; _i < awaitingCandidates_1.length; _i++) {
                            var candidate = awaitingCandidates_1[_i];
                            connection._pc.addIceCandidate({
                                candidate: candidate.payload.candidate,
                                sdpMid: candidate.payload.mid
                            });
                        }
                    }
                    return;
                }
            }
            catch (err) {
                connection.log_debug(err);
            }
            try {
                if (connection._on_ws_message) {
                    connection._on_ws_message(event.data);
                }
            }
            catch (err) {
                connection.log_debug(err);
            }
        };
        connection._ws.onerror = function (event) {
            console.error(event);
        };
        connection._ws.onclose = function (event) {
            connection.log_debug("Closing ws");
            console.log("Closing ws");
            connection.close();
        };
        var ws_opened = new Promise(function (resolve) {
            connection._ws.onopen = function (event) {
                resolve();
            };
        });
        connection._pc = new RTCPeerConnection(conf.pc);
        connection._pc.onicecandidate = function (event) {
            if (event.candidate && connection._ws) {
                connection._ws.send(JSON.stringify({
                    action: "peerconnection-creation-new-candidate",
                    payload: {
                        candidate: event.candidate,
                    }
                }));
            }
        };
        connection._pc.onicecandidateerror = function (event) {
            console.error(event);
        };
        connection._pc.oniceconnectionstatechange = function (event) {
            connection.log_debug("oniceconnectionstatechange", connection._dc);
            connection.log_debug(event);
        };
        connection._pc.onconnectionstatechange = function (event) {
            connection.log_debug("onconnectionstatechange", connection._dc);
            connection.log_debug(event);
        };
        connection._pc.onsignalingstatechange = function (event) {
            connection.log_debug("onsignalingstatechange", connection._dc);
            connection.log_debug(event);
        };
        // connection._pc.onnegotiationneeded = async (event) => {
        //     connection.log_debug("onnegotiationneeded");
        //     try {
        //         const offer = await connection._pc.createOffer();
        //         if (connection._pc.signalingState !== "stable") {
        //             connection.log_debug("Connection still unstable");
        //             return;
        //         }
        //
        //         await connection._pc.setLocalDescription(offer);
        //
        //         connection.send_ws(JSON.stringify({
        //             action: "peerconnection-creation-offer",
        //             payload: {
        //                 sdp: offer.sdp,
        //                 type: offer.type,
        //             }
        //         }));
        //     } catch (err) {
        //         console.error(err);
        //     }
        // }
        connection._dc = connection._pc.createDataChannel(conf.dc.label, conf.dc);
        connection._dc.onmessage = function (event) {
            try {
                if (connection._on_dc_message) {
                    connection._on_dc_message(event.data);
                }
            }
            catch (err) {
                console.error(err);
            }
        };
        connection._dc.onerror = function (event) {
            console.error(event);
        };
        // connection._dc.onclosing
        connection._dc.onclose = function (event) {
            connection.log_debug("Closing dc");
            console.log("Closing dc");
            connection.close();
        };
        // connection._dc.onbufferedamountlow
        return Promise.all([
            ws_opened.then(function () {
                return connection._pc.createOffer();
            }).then(function (offer) {
                connection.log_debug("Created offer");
                return connection._pc.setLocalDescription(offer);
            }).then(function () {
                var offer = connection._pc.localDescription;
                if (offer === null) {
                    throw new Error("Unable to create offer for RTCPeerConnection");
                }
                connection.log_debug("Send offer");
                connection._ws.send(JSON.stringify({
                    action: "peerconnection-creation-offer",
                    payload: {
                        sdp: offer.sdp,
                        type: offer.type,
                    },
                }));
            }),
            new Promise(function (resolve) {
                connection._dc.onopen = function (event) {
                    connection.log_debug("dc opened");
                    resolve();
                };
            })
        ]).then(function () {
            return connection;
        });
    };
    Object.defineProperty(Connection.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: false,
        configurable: true
    });
    Connection.prototype.close = function () {
        this._ws.close();
        this._dc.close();
        this._pc.close();
    };
    Connection.prototype.on_ws_message = function (listener) {
        this._on_ws_message = listener;
    };
    Connection.prototype.on_dc_message = function (listener) {
        this._on_dc_message = listener;
    };
    Connection.prototype.send_ws = function (data) {
        this._ws.send(data);
    };
    Connection.prototype.send_dc = function (data) {
        // @ts-ignore
        this._dc.send(data);
    };
    Connection.prototype.log_debug = function (message) {
        var optionalParams = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            optionalParams[_i - 1] = arguments[_i];
        }
        if (this._verbose_logs) {
            console.log.apply(console, __spreadArray([message], optionalParams, false));
        }
    };
    return Connection;
}());
export { Connection };
