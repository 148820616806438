import React from 'react';
import './App.css';
import './Broadcast'
import Broadcast from "./Broadcast";

function App() {
    return (
        <div>
            <Broadcast></Broadcast>
            {/*<Broadcast></Broadcast>*/}
            {/*<Broadcast></Broadcast>*/}
        </div>
    );
}

export default App;
