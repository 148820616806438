import {useEffect, useState} from "react";
import * as pluto from "pluto_client"
import {Connection_config, Client} from "pluto_client";

export interface IUseClientCallbacks {
    on_ws_message?: (data: pluto.On_message_data) => any;
    on_dc_message?: (data: pluto.On_message_data) => any;
    on_connection_opened?: (data: pluto.On_connection_opened_data) => any;
    on_connection_joined?: (data: pluto.On_connection_joined_data) => any;
    on_connection_left?: (data: pluto.On_connection_left_data) => any;
    on_list_connections?: (data: pluto.On_list_connections_data) => any;
    on_room_created?: (data: pluto.On_room_created_data) => any;
    on_room_closed?: (data: pluto.On_room_closed_data) => any;
    on_joined_room?: (data: pluto.On_joined_room_data) => any;
    on_left_room?: (data: pluto.On_left_room_data) => any;
    on_list_rooms?: (data: pluto.On_list_rooms_data) => any;
    on_list_room_connections?: (data: pluto.On_list_room_connections_data) => any;
    on_pull_connection_data?: (data: pluto.On_pull_connection_client_data) => any;
    on_pull_room_data?: (data: pluto.On_pull_room_client_data) => any;
    on_error?: (data: pluto.On_error_data) => any;
}

export function useClient(config: Connection_config, {
    on_ws_message,
    on_dc_message,
    on_connection_opened,
    on_connection_joined,
    on_connection_left,
    on_list_connections,
    on_room_created,
    on_room_closed,
    on_joined_room,
    on_left_room,
    on_list_rooms,
    on_list_room_connections,
    on_pull_connection_data,
    on_pull_room_data,
    on_error,
}: IUseClientCallbacks) {
    const [client, setClient] = useState<Client | null>(null);

    useEffect(() => {
        Client.create(config).then((c: Client) => {
            c.on_ws_message = on_ws_message;
            c.on_dc_message = on_dc_message;
            c.on_connection_opened = on_connection_opened;
            c.on_connection_joined = on_connection_joined;
            c.on_connection_left = on_connection_left;
            c.on_list_connections = on_list_connections;
            c.on_room_created = on_room_created;
            c.on_room_closed = on_room_closed;
            c.on_joined_room = on_joined_room;
            c.on_left_room = on_left_room;
            c.on_list_rooms = on_list_rooms;
            c.on_list_room_connections = on_list_room_connections;
            c.on_pull_connection_data = on_pull_connection_data;
            c.on_pull_room_data = on_pull_room_data;
            c.on_error = on_error;
            setClient(c);
        });
        return () => {
            client?.close();
        };
    }, [config, on_ws_message, on_dc_message, on_connection_opened, on_connection_joined, on_connection_left,
        on_list_connections, on_room_created, on_room_closed, on_joined_room, on_left_room, on_list_rooms,
        on_list_room_connections, on_error]);
    return client;
}