import './Broadcast.css';
import {ChangeEvent, useEffect, useRef, useState} from "react";
import * as pluto from "pluto_client"
import {IUseClientCallbacks, useClient} from "./useClient"
import {
    Connection_id,
    Room_id
} from "pluto_client";

interface IMessageListProps {
    items: string[];
}

function MessageList(props: IMessageListProps) {
    const messagesEndRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: "smooth"})
    }

    useEffect(() => {
        scrollToBottom()
    }, [props.items]);

    return (
        <ul className={"in-msg"}>
            {props.items.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
            <div ref={messagesEndRef}/>
        </ul>
    )
}

interface IBroadcastProps {
    className?: string | undefined;
}

function unique<T>(array: T[]) {
    return array.filter((v, i, a) => {
        return a.indexOf(v) === i;
    });
}

function Broadcast(props: IBroadcastProps) {
    const [inboxMessages, setInboxMessages] = useState<string[]>([]);
    const [messageText, setMessageText] = useState("");
    const [connectionsList, setConnectionsList] = useState<Connection_id[]>([]);
    const [roomsList, setRoomsList] = useState<Room_id[]>([]);
    const [roomConnectionsList, setRoomConnectionsList] = useState<Connection_id[]>([]);
    const [desiredRoomId, setDesiredRoomId] = useState<string>();
    const [selectedConnections, setSelectedConnections] = useState<Connection_id[]>([]);
    const [connectionData, setConnectionData] = useState<string>("");
    const [roomData, setRoomData] = useState<string>("");
    const inputRoomDataEl = useRef<HTMLInputElement>(null);


    const config = useRef(new pluto.Connection_config({
        // url: "ws://localhost:8081"
        url: "wss://server-v2.wondersouq.web3dev.group/"
    }, new pluto.Dc_config("chat")));
    config.current.pc.iceServers = [{urls: "stun:server-v2.wondersouq.web3dev.group:3478"}];
    let client: pluto.Client | null = null;
    const callbacks = useRef<IUseClientCallbacks>(
        {
            on_ws_message: (message) => {
                setInboxMessages((prevText) => {
                    const id = message.payload.sender;
                    const text = message.action === "server-broadcast-ws" || message.action === "server-broadcast-dc"
                        ? (message as any).payload.message.inner
                        : message.payload.message;
                    const method = "ws";
                    return [...prevText, `${id}(${method}): ${text}`];
                });
            },
            on_dc_message: (message) => {
                if (!(message.payload.message.inner && message.payload.message.inner.spam)) {
                    setInboxMessages((prevText) => {
                        const id = message.payload.sender;
                        const text = message.action === "server-broadcast-ws" || message.action === "server-broadcast-dc"
                            ? (message as any).payload.message.inner
                            : message.payload.message;
                        const method = "dc";
                        const time_stamp = message.send_time;
                        return [...prevText, `${id}(${method})[${time_stamp}]: ${text}`];
                    });
                } else {
                    if (message.payload.message.inner.index % 100 === 0) {
                        console.log(
                            "time diff: ",
                            (Date.now() - message.payload.message.inner.spam).toString(),
                            " ", message.payload.message.inner.index
                        );
                    }
                }
            },
            on_connection_opened: (data) => {
                setConnectionsList(data.payload.connection_ids);
            },
            on_connection_joined: (data) => {
                setConnectionsList((prevList) => {
                    prevList.push(data.payload.connection_id);
                    return unique(prevList);
                });
            },
            on_connection_left: (data) => {
                setConnectionsList((prevList) => {
                    return prevList.filter((item) => item !== data.payload.connection_id);
                });
            },
            on_list_connections: (data) => {
                setConnectionsList(data.payload.connection_ids);
            },
            on_room_created: (data) => {
                console.log(data);
                setRoomsList((prevList) => {
                    prevList.push(data.payload.room_id);
                    return unique(prevList);
                });
            },
            on_room_closed: (data) => {
                console.log(data);
                setRoomsList((prevList) => {
                    return prevList.filter((item) => item !== data.payload.room_id);
                });
            },
            on_joined_room: (data) => {
                setRoomConnectionsList((prevList) => {
                    prevList.push(data.payload.connection_id);
                    return unique(prevList);
                });
                client?.pull_room_data(data.payload.room_id);
            },
            on_left_room: (data) => {
                setRoomConnectionsList((prevList) => {
                    return prevList.filter((item) => item !== data.payload.connection_id);
                });
            },
            on_list_rooms: (data) => {
                setRoomsList(data.payload.room_ids);
            },
            on_list_room_connections: (data) => {
                setRoomConnectionsList(data.payload.connection_ids);
            },
            on_pull_connection_data: (data) => {
                setConnectionData(data.payload.data);
            },
            on_pull_room_data: (data) => {
                setRoomData(data.payload.data);
                if (inputRoomDataEl.current) {
                    inputRoomDataEl.current.value = data.payload.data;
                }
            },
            on_error: (data) => {
                console.error(data);
            },
        }
    );
    client = useClient(config.current, callbacks.current);

    function list_connections() {
        client?.list_connections();
    }

    function message_ws() {
        client?.message_ws(selectedConnections, messageText);
    }

    function message_dc() {
        client?.message_dc(selectedConnections, messageText);
    }

    function server_broadcast_ws() {
        client?.server_broadcast_ws({inner: messageText});
    }

    function server_broadcast_dc() {
        client?.server_broadcast_dc({inner: messageText});
    }

    function create_room() {
        client?.create_room();
    }

    function close_room() {
        client?.close_room();
    }

    function join_room() {
        client?.join_room(desiredRoomId as Room_id);
    }

    function leave_room() {
        client?.leave_room();
    }

    function room_broadcast_ws() {
        client?.room_broadcast_ws(messageText);
    }

    function room_broadcast_dc() {
        client?.room_broadcast_dc(messageText)
    }

    function list_rooms() {
        client?.list_rooms();
    }

    function list_room_connections() {
        if (client?.room_id) {
            client?.list_room_connections(client?.room_id);
        }
    }

    function handle_message_change(e: ChangeEvent<HTMLInputElement>) {
        setMessageText(e.target.value);
    }

    function handle_connection_list_change(e: ChangeEvent<HTMLSelectElement>) {
        let connections = [];
        for (let i = 0; i < e.target.options.length; i++) {
            if (e.target.options[i].selected) {
                connections.push(e.target.options[i].value);
            }
        }
        setSelectedConnections(connections);
    }

    function handle_room_list_change(e: ChangeEvent<HTMLSelectElement>) {
        setDesiredRoomId(e.target.value);
    }

    function handle_room_connections_list_change(e: ChangeEvent<HTMLSelectElement>) {

    }

    function spam_dc() {
        // let start_time = performance.now();
        // for (let i = 0; i < 10000; ++i) {
        //     client?.server_broadcast_dc({
        //         inner: {
        //             spam: Date.now().toString()
        //         }
        //     });
        // }
        // console.log(performance.now() - start_time);
        let i = 0;
        let dat: number[] = [];
        for (let j = 0; j < 2000; j++) {
            dat.push(j);
        }
        setInterval(() => {
            i += 1;
            client?.server_broadcast_dc({
                inner: {
                    spam: Date.now().toString(),
                    index: i,
                    data: dat,
                }
            });
        }, 5);
    }

    function set_room_data() {
        if (client?.room_id && inputRoomDataEl.current) {
            client?.push_room_data(client?.room_id, inputRoomDataEl.current.value);
        }
    }

    function get_room_data() {
        if (client?.room_id && inputRoomDataEl.current) {
            client?.pull_room_data(client?.room_id);
        }
    }

    return (
        <div className={"cols frame " + props.className}>
            <div className={"rows"}>
                <div id="connection-info">
                    <p>Client ready: {client === null ? "not ready" : "ready"}</p>
                    <p>Client id: {client?.id} | Room_id: {client?.room_id}</p>
                    <button type="button" onClick={join_room}>Join</button>
                    <button type="button" onClick={leave_room}>Leave</button>
                    <button type="button" onClick={list_connections}>Refresh connections list</button>
                    <button type="button" onClick={create_room}>Create room</button>
                    <button type="button" onClick={close_room}>Close room</button>
                    <button type="button" onClick={list_rooms}>Refresh rooms list</button>
                    <button type="button" onClick={list_room_connections}>Refresh room connections list</button>
                </div>
                <div id="connections-list">
                    <label htmlFor="connections-list">Connections:</label>
                    <select name="connections-list" multiple onChange={handle_connection_list_change}>
                        {connectionsList.map((item, index) => (
                            <option key={item}>{item}</option>
                        ))}
                    </select>
                </div>
                <div className={"cols"}>
                    <div id="rooms-list">
                        <label htmlFor="rooms-list">Rooms:</label>
                        <select name="rooms-list" multiple onChange={handle_room_list_change}>
                            {roomsList.map((item, index) => (
                                <option key={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                    <div id="room-connections-list">
                        <label htmlFor="room-connections-list">Room connections:</label>
                        <select name="room-connections-list" multiple onChange={handle_room_connections_list_change}>
                            {roomConnectionsList.map((item, index) => (
                                <option key={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div>
                <label htmlFor="room-data">Message:</label>
                <input id="room-data" name="room-data" ref={inputRoomDataEl} type="text"/>
                <button type="button" onClick={set_room_data}>Push</button>
                <button type="button" onClick={get_room_data}>Pull</button>
            </div>
            <div id="message-input-area">
                <div>
                    <button type="button" onClick={message_ws}>Message WS</button>
                    <button type="button" onClick={message_dc}>Message DC</button>
                    <button type="button" onClick={server_broadcast_ws}>Server broadcast WS</button>
                    <button type="button" onClick={server_broadcast_dc}>Server broadcast DC</button>
                    <button type="button" onClick={room_broadcast_ws}>Room broadcast WS</button>
                    <button type="button" onClick={room_broadcast_dc}>Room broadcast DC</button>
                    <button type="button" onClick={spam_dc}>Spam DC</button>
                </div>
                <label htmlFor="message-input">Message:</label>
                <input id="message" name="message" type="text" onChange={handle_message_change}/>
            </div>
            <MessageList items={inboxMessages}/>
        </div>
    );
}

export default Broadcast;